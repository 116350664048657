import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatCurrency = (amount: number | string | null) => {
  if (!amount) return '-';

  let normalizedAmount: number;

  if (typeof amount === 'string') {
    normalizedAmount = parseFloat(amount);
  } else {
    normalizedAmount = amount;
  }

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(normalizedAmount);
};

export const formatDate = (date: string | Date) => {
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(date));
};

export const formatDateForInput = (date: string | Date | null | undefined): string => {
  if (!date) return '';
  return new Date(date).toISOString().split('T')[0];
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
